import React from 'react';
import './Advertising.css';


const Advertising: React.FC = () => {

    return (
        <div className="ad-container d-flex-column">
           <p>Advertising</p>
           <p>Advertising</p>
           <p>Advertising</p>
        </div>
    );
};

export default Advertising;
