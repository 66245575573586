import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import axios from '../api/axios';
import * as Yup from 'yup'
import questions from './Questions';
import AuthService from '../service/AuthService';
import { useNavigate } from 'react-router-dom';

import './Questionnaire.css';

const Questionnaire: React.FC = () => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [isMovingForward, setIsMovingForward] = useState(true);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

  const initialValues: { [key: string]: string } = {};
  questions.forEach((question) => {
    initialValues[question.id] = '';
  });

  const validationSchema = Yup.object(
    questions.reduce((schema, question) => {
      schema[question.id] = Yup.string().required('This field is required');
      return schema;
    }, {} as { [key: string]: Yup.StringSchema })
  );

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            setErrorMessage('');
            try {
                await axios.post('questionnaire/submit', { values });
                navigate('/feed');
            } catch (error) {
                setErrorMessage('Error submitting questionnaire. Please try again.');
                console.error('Error submitting questionnaire:', error);
            }
        },
    });

  useEffect(() => {
    if (currentQuestionIndex + 1 < questions.length) {
      if (isMovingForward && currentQuestionIndex >= 0 && formik.values[questions[currentQuestionIndex].id]) {
        handleNext();
      }
    }
  }, [formik.values, currentQuestionIndex]);

  const handleNext = () => {
    if (formik.values[questions[currentQuestionIndex].id]) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      formik.setTouched({ ...formik.touched, [questions[currentQuestionIndex].id]: true });
    }
  };

  const handlePrevious = () => {
    setIsMovingForward(false);
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    setIsMovingForward(true);
  };

  const handleStart = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  if (currentQuestionIndex === -1) {
    return (
      <div className="questionnaire-container">
        <img className="questionnaire-img" alt="Woman posing" src="/images/questionnaire/questionnaire-side-image.png"/>
        <div className="questionnaire">
          <h2>Let's find your skin twin!</h2>
          <p>You’ll go trough a 5 minute Questionnaire so we can find your skin twin! This Questionnaire is essential to your experience!</p>
          <button className="btn mt-2" onClick={handleStart}>Continue</button>
       </div>
      </div>
    );
  }

  return (
    <div className="questionnaire-container">
    <img className="questionnaire-img" alt="Woman posing" src="/images/questionnaire/questionnaire-side-image.png"/>
    {currentQuestionIndex >= 0 && (
      <form onSubmit={formik.handleSubmit} className="questionnaire">
        <div className="form-group">
          <h2><label htmlFor={questions[currentQuestionIndex].id}>
            {questions[currentQuestionIndex].label}
          </label></h2>
          <div className="d-flex justify-content-space-between">
          {questions[currentQuestionIndex].options.map((option, idx) => (
            <div key={idx} className="questions justify-content-space-between">
              <label htmlFor={`${questions[currentQuestionIndex].id}_option${idx}`}>{option}</label>
              <input
                type="radio"
                id={`${questions[currentQuestionIndex].id}_option${idx}`}
                name={questions[currentQuestionIndex].id}
                value={option}
                onChange={handleOptionChange}
                checked={formik.values[questions[currentQuestionIndex].id] === option}
              />
            </div>
          ))}
          </div>
          {formik.touched[questions[currentQuestionIndex].id] && formik.errors[questions[currentQuestionIndex].id] ? (
            <div className="error">{formik.errors[questions[currentQuestionIndex].id]}</div>
          ) : null}
        </div>
        <div className="progress-bar">
            <div
                className="progress"
                style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}
            >
            </div>
        </div>
        <div className="button-group">
          {currentQuestionIndex >= 0 && (
            <button type="button" onClick={handlePrevious} className="btn mt-2">
              Back
            </button>
          )}
          {currentQuestionIndex < questions.length - 1 && (
            <button type="button" onClick={handleNext} className="btn mt-2">
              Next
            </button>
          )}
          {currentQuestionIndex === questions.length - 1 && (
            <button type="submit" className="btn mt-2">
              Submit
            </button>
          )}
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </form>
      )}
    </div>
  );
};

export default Questionnaire;
