import React, { useState } from 'react';
import axios from '../api/axios';
import './NewPostForm.css';

interface NewPostFormProps {
    onSubmitSuccess: () => void;
}

const NewPostForm: React.FC<NewPostFormProps> = ({ onSubmitSuccess }) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [errors, setErrors] = useState<{ title?: string; content?: string }>({});

    const validate = () => {
        const newErrors: { title?: string; content?: string } = {};
        if (!title) newErrors.title = 'Title is required';
        if (!content) newErrors.content = 'Content is required';
        return newErrors;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                const response = await axios.post('posts', { title, content });
                console.log('NewPostForm called: ', response.data)
                setTitle('');
                setContent('');
                setErrors({});
                onSubmitSuccess();
            } catch (error) {
                console.error('Error creating post:', error);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="new-post-form">
            <h3>Create a New Post</h3>
            <input
                type="text"
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder="Title"
                className={`new-post-title ${errors.title ? 'input-error' : ''}`}
            />
            <textarea
                value={content}
                onChange={e => setContent(e.target.value)}
                rows={10}
                placeholder="What's on your mind? Don’t forget to add a #hashtag to get more traction!"
                className={`new-post-content ${errors.content ? 'input-error' : ''}`}
            ></textarea>
            {errors.title && <span className="error">{errors.title}</span>}
            {errors.content && <span className="error">{errors.content}</span>}
            <button type="submit">Post</button>
        </form>
    );
};

export default NewPostForm;
