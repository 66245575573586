import "./Footer.css";

const Footer: React.FC = () => {
    return (
        <div className="footer">
            <div className="footer-title mt-4">skintwin</div>
            <div className="d-flex-row justify-content-space-between mt-1 footer-icon-container mb-2">
                <img className="footer-icon" alt="Facebook icon" src="/images/home/facebook-icon.svg"/>
                <img className="footer-icon" alt="Linkedin icon" src="/images/home/linkedin-icon.svg"/>
                <img className="footer-icon" alt=" Youtube icon" src="/images/home/youtube-icon.svg"/>
                <img className="footer-icon" alt="Instagram icon" src="/images/home/instagram-icon.svg"/>
            </div>
        </div>
    );
};

export default Footer;