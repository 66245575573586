import React, { useState } from 'react';
import axios from '../api/axios';
import { Reply as ReplyType } from '../../interfaces';

import './ReplyForm.css';

interface ReplyFormProps {
    postId?: number;
    parentReplyId? : number;
    onReplySuccess: (newReply: ReplyType) => void;
}

const ReplyForm: React.FC<ReplyFormProps> = ({ postId, parentReplyId, onReplySuccess }) => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = postId
                ? await axios.post(`replies/${postId}`, { content })
                : await axios.post(`replies/child/${parentReplyId}`, { content });
            console.log('ReplyForm called: ', response.data)
            setContent('');
            onReplySuccess(response.data); // Call the success handler to refresh the replies
        } catch (error) {
            console.error('Error posting reply:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="reply-form">
            <textarea
                value={content}
                onChange={e => setContent(e.target.value)}
                placeholder="Write a reply..."
                disabled={loading}
            ></textarea>
            <button type="submit" disabled={loading}>
                {loading ? "Posting..." : "Reply"}
            </button>
        </form>
    );
};

export default ReplyForm;