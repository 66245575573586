import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyApDq6C99PyeJUmP8N7StNByYGjizDFMwc",
    authDomain: "fourth-amp-332316-332909.firebaseapp.com",
    projectId: "fourth-amp-332316-332909",
    storageBucket: "fourth-amp-332316-332909.appspot.com",
    messagingSenderId: "776065074413",
    appId: "1:776065074413:web:6c08515329b7176c0438e4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
