import React, { useState, useEffect } from 'react';
import ReplyForm from './ReplyForm';
import Reply from './Reply';
import axios from '../api/axios';
import { Post as PostType, Reply as ReplyType } from '../../interfaces';

import './Post.css';

interface PostProps {
    post: PostType;
}

const emojis = ["👍", "❤️", "😂", "😮", "😢", "👏"];
const defaultEmoji = "👍";

const Post: React.FC<PostProps> = ({ post }) => {
    const [showReplies, setShowReplies] = useState(false);
    const [replies, setReplies] = useState<ReplyType[]>(post.replies);
    const [loadingReplies, setLoadingReplies] = useState(false);
    const [emojiCounts, setEmojiCounts] = useState<Record<string, number>>(post.likes || {});
    const [userSelectedEmoji, setUserSelectedEmoji] = useState<string | null>(null);
    const [showEmojiMenu, setShowEmojiMenu] = useState(false);

    useEffect(() => {
        fetchPostReactions();
    }, []);

    const fetchReplies = async () => {
        setLoadingReplies(true);
        try {
            console.log(post.id);
            const response = await axios.get(`replies/${post.id}`);
            console.log('Fetch replies: ', response.data);
            setReplies(response.data);
        } catch (error) {
            console.error('Error fetching replies:', error);
        } finally {
            setLoadingReplies(false);
        }
    };

    const handleReplyClick = () => {
        if (!showReplies) {
            fetchReplies();
        }
        setShowReplies(!showReplies);
    };

    const handleReplySuccess = (newReply: ReplyType) => {
        setReplies([...replies, newReply]);
    };

    const fetchPostReactions = async () => {
        try {
            const response = await axios.get(`posts/${post.id}/like`);
            console.log('Fetch post reactions: ', response.data);
            setEmojiCounts(response.data.emojiCounts || {});
            setUserSelectedEmoji(response.data.userSelectedEmoji);
        } catch (error) {
            console.error('Error fetching post reactions:', error);
        }
    };

    const handleLikeClick = () => {
        setShowEmojiMenu(!showEmojiMenu);
    };

    const handleEmojiSelect = async (emoji: string) => {
        try {
            const response = await axios.post(`posts/${post.id}/like`, { emoji });
            console.log('Update post reactions: ', response.data);
            setEmojiCounts(response.data.emojiCounts || {});
            setUserSelectedEmoji(response.data.userSelectedEmoji || null);
        } catch (error) {
            console.error('Error liking post:', error);
        }
        setShowEmojiMenu(false);
    };

    // Generate the display text for the button based on emoji counts
    const emojiButtonText = Object.entries(emojiCounts)
        .filter(([_, count]) => count > 0)
        .map(([emoji, count]) => (
            <span
                key={emoji}
                className={emoji === userSelectedEmoji ? 'highlighted-emoji' : ''}
            >
                {emoji} {count}
            </span>
        ));

    return (
        <div>
            <div className="post-header">
                <img src={post.user.profilePicture} alt="User" className="post-user-photo" />
                <div className="post-user-info">
                    <span className="post-username">Posted by {post.user.name}</span>
                    <span className="post-title">{post.title}</span>
                </div>
            </div>
            <div className="post-content mt-1">
                <p>{post.content}</p>
            </div>
            <div className="post-actions mt-2">
                <button className="post-action-button" onClick={handleLikeClick}>
                    {emojiButtonText.length > 0 ? emojiButtonText : defaultEmoji}
                </button>
                {showEmojiMenu && (
                    <div className="emoji-menu">
                        {emojis.map(emoji => (
                            <button
                                key={emoji}
                                className="emoji-button"
                                onClick={() => handleEmojiSelect(emoji)}
                            >
                                {emoji}
                            </button>
                        ))}
                    </div>
                )}
                <button className="post-action-button" onClick={handleReplyClick}>
                    {showReplies ? "Hide Replies" : "Reply"}
                </button>
            </div>
            {showReplies && (
                <div className="post-replies">
                    {loadingReplies ? (
                        <p>Loading replies...</p>
                    ) : (
                        replies.map(reply => <Reply key={reply.id} reply={reply} />)
                    )}
                    <ReplyForm postId={post.id} onReplySuccess={handleReplySuccess} />
                </div>
            )}
        </div>
    );
};

export default Post;