import React, {useState} from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import AuthService from '../service/AuthService';

import "./Header.css";

const Header: React.FC = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        const confirmLogout = window.confirm('Are you sure you want to sign out?')
        if (confirmLogout) {
           AuthService.signOut();
           navigate('/signin');
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

  return (
    <div className="header d-flex-row justify-content-space-between">
    {AuthService.isAuthenticated() ? (
        <Link to="/feed"><img className="skintwin-logo" alt="Skintwin logo purple"
                                 src="/images/home/skintwin-logo-purple-text-bigger-2.png"/>
                     </Link>
    ) : (
        <Link to="/"><img className="skintwin-logo" alt="Skintwin logo purple"
                                 src="/images/home/skintwin-logo-purple-text-bigger-2.png"/></Link>
    )}
      <button className="hamburger" onClick={toggleMenu}>
        {/* Hamburger icon (3 lines) */}
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
      <div className={`d-flex-row justify-content-space-between nav-links ${isOpen ? 'open' : ''}`}>
        <div className="d-flex-row justify-content-space-between">
            <NavLink to="/about" className={({ isActive }) => (isActive ? 'header-link header-active' : 'header-link')}><p>About us</p></NavLink>
            <NavLink to="/find-your-skintwin" className={({ isActive }) => (isActive ? 'header-link header-active' : 'header-link')}><p>Find your Skintwin</p></NavLink>
            <NavLink to="/skintwin-for-business" className={({ isActive }) => (isActive ? 'header-link header-active' : 'header-link')}><p>Skintwin for business</p></NavLink>
        </div>
        <div className="separator">
            {AuthService.isAuthenticated() ? (
                <Link to="/" className={`${isOpen ? 'header-link' : 'btn login-button'}`} onClick={ handleLogout }>Logout</Link>
            ) : (
                <NavLink to="/signin" className={({ isActive }) => (isOpen ? (isActive ? 'header-link header-active' : 'header-link') : (isActive ? 'btn login-button btn-active' : 'btn login-button'))}>Login</NavLink>
            )}
            <NavLink to="/signup" className={({ isActive }) => (isOpen ? (isActive ? 'header-link header-active' : 'header-link') : (isActive ? 'btn login-button btn-active' : 'btn login-button'))}>Register</NavLink>
        </div>
      </div>
    </div>
  );
};

export default Header;