import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

import ProtectedRoute from "./components/protection/ProtectedRoute";
import Home from './components/Home';
import About from './components/About';
import FindYourSkintwin from './components/FindYourSkintwin';
import SkintwinForBusiness from './components/SkintwinForBusiness';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import Questionnaire from './components/questionnaire/Questionnaire';
import ResetPassword from './components/auth/ResetPassword';
import Feed from './components/feed/Feed';

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about"  element={<About />} />
                    <Route path="/find-your-skintwin"  element={<FindYourSkintwin />} />
                    <Route path="/skintwin-for-business"  element={<SkintwinForBusiness />} />
                    <Route path="/questionnaire" element={<Questionnaire />} />
                    <Route path="/feed" element={<Feed />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    {/*<Route path="*" element={<Navigate to="/" />} />*/}
                </Route>
            </Routes>
        </Router>
    );
};

export default App;