import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import AuthService from '../service/AuthService';
import './Auth.css';
import Header from '../common/Header';
import Footer from '../common/Footer';

const SignIn: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [emailEntered, setEmailEntered] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: email || '',
      password: '',
      rememberMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    }),
    onSubmit: async (values) => {
      setErrorMessage('');
      try {
        await AuthService.signIn(values.email, values.password, values.rememberMe);
        navigate(AuthService.hasCompletedQuestionnaire() ? '/feed' : '/questionnaire');

      } catch (err: any) {
        if (err.statusCode !== '200') {
            if (err.error === 'No value present') {
                setErrorMessage('Invalid email. Please try again.');
            }
            else {
                setErrorMessage('Invalid password. Please try again.');
            }
        }
        console.error('Error signing in:', err);
      }
    },
  });

  const handleGoogleSignIn = async () => {
    try {
//       const user = await AuthService.googleSignIn();
//       console.log(user)
//       if (user) {
//         navigate(AuthService.hasCompletedQuestionnaire() ? '/feed' : '/questionnaire');
//       }
    } catch (error) {
      console.error('Google Login failed:', error);
      setErrorMessage('Google Login failed. Please try again.');
    }
  };

  const handleEmailSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmailEntered(true);
  };

  return (
    <div>
    <Header />
    <div className="sign-container">
    <h2 className="text-center mt-5 mb-3">A platform dedicated to skincare.</h2>
      <div className="form-container p-3 mt-2">
        <div className="sign-container">
        <h3>Sign In</h3>
        <form onSubmit={formik.handleSubmit} className="form-group">
        <div className="form-group">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="form-group">
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="error">{formik.errors.password}</div>
          ) : null}
        </div>
        <div className="form-group d-flex-row justify-content-flex-start remember-me">
          <label>Remember Me</label>
            <input
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              onChange={formik.handleChange}
              checked={formik.values.rememberMe}
              className="remember-me"
            />
            <button type="submit" className="btn sign-up-email">Sign In</button>
        </div>
        {errorMessage && <div className="error">{errorMessage}</div>}
          {/*<button type="button" className="btn" onClick={() => navigate('/reset-password')}>Forgot Password?</button>*/}
      </form>
            <p>or continue with</p>
            <button onClick={handleGoogleSignIn} className="btn sign-up-google mt-2 mb-2">Sign in with Google</button>
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default SignIn;
