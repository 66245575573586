import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebaseConfig'
import AuthService from '../service/AuthService';
import axios from 'axios';

import Header from '../common/Header';
import Footer from '../common/Footer';
import './Auth.css';

const Register: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [authMethod, setAuthMethod] = useState<'email' | 'google' | null>(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
      confirmPassword: '',
      dateOfBirth: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Username is required'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
        .required('Confirm Password is required'),
      dateOfBirth: Yup.date().required('Date of birth is required'),
    }),
    onSubmit: async (values) => {
      setErrorMessage('');
      try {
          await AuthService.signUp(values.name, email, values.password, values.dateOfBirth);
          navigate('/questionnaire'); // Navigate to questionnaire after successful signup

      } catch (err: any) {
        if (err.statusCode == '500') {
            setErrorMessage('Failed to Sign Up, try again!');
        }
        console.error('Error signing in:', err);
      }
    },
  });

  const handleGoogleSignUp = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      setEmail(user?.email || '');
      setAuthMethod('google');
    } catch (error) {
      console.error('Error signing in with Google', error);
    }
  };

  const handleEmailSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
      setAuthMethod('email');
    }
  };

  const handleBack = () => {
    setAuthMethod(null);
  };

  return (
    <div>
    <Header />
    <div className="sign-container">
    <h2 className="text-center mt-5 mb-3">A platform dedicated to skincare.</h2>
      <div className="form-container p-3 mt-2">
      {!authMethod && (
        <div className="sign-container">
          <h3 className="mt-2 mb-1">Create an account</h3>
          <p className="mb-1">Enter your email to sign up for this app</p>
            <div className="mt-2 form-group">
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {emailError && <div className="error">{emailError}</div>}
            </div>
          <button className="btn sign-up-email mb-2" onClick={handleEmailSubmit}>Sign up with Email</button>
          <p>or continue with</p>
          <button className="btn sign-up-google mt-2 mb-2" onClick={handleGoogleSignUp}>Sign up with Google</button>
          <p className="terms-policy">By clicking continue, you agree to our Terms of Service and Privacy Policy</p>
        </div>
      )}
      {(authMethod === 'email' || authMethod === 'google') && (
        <form onSubmit={formik.handleSubmit}>
        <h4 className="mt-2 mb-1">You're almost there!</h4>
        <p className="mb-1">What would you like to be called?</p>
          {authMethod === null && (
            <div className="form-group">
              <input
                type="hidden"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                readOnly
              />
            </div>
          )}
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="error">{formik.errors.confirmPassword}</div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth}
            />
            {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
              <div className="error">{formik.errors.dateOfBirth}</div>
            ) : null}
          </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="button-group mt-2">
          <button type="button" className="btn submit-button" onClick={handleBack}>Back</button>
          <button type="submit" className="btn submit-button">Finish</button>
          </div>
        </form>
      )}
      </div>
    </div>
        <Footer />
    </div>
  );
};

export default Register;