import {Link} from "react-router-dom";

import Header from './common/Header';
import Footer from './common/Footer';

const SkintwinForBusiness: React.FC = () => {
    return (
        <div>
          <Header />

          <Footer />
        </div>
    );
};

export default SkintwinForBusiness;