export interface Question {
  id: string;
  label: string;
  options: string[];
}

export const questions: Question[] = [
  {
    id: 'question1',
    label: 'What is your skin type?',
    options: ['Dry Skin', 'Oily Skin', 'Combination Skin', 'Normal or Balanced Skin'],
  },
  {
    id: 'question2',
    label: 'What is the texture of your skin?',
    options: ['Smooth', 'Rough or Bumpy', 'Uneven or Textured', 'Dry or Tight'],
  },
  {
    id: 'question3',
    label: 'How does your skin typically feel throughout the day?',
    options: ['Dry and Tight', 'Balanced (neither too dry nor too oily)', 'Irritated or Sensitive', 'Comfortable and Hydrated', 'Itchy or Uncomfortable', 'Other (specify)'],
  },
  {
    id: 'question4',
    label: 'What is your skin tone?',
    options: ['Fair or Light', 'Medium Skin Tone', 'Dark or Deep Skin Tone', 'Olive Skin Tone'],
  },
  {
    id: 'question5',
    label: 'Do you have any specific skin concerns?',
    options: ['Acne', 'Hormonal Breakouts', 'Blackheads', 'Aging', 'Dryness', 'Oiliness', 'Large Pores or Dilated', 'Rosacea', 'Sunspots', 'Freckles', 'Blotched Skin', 'Eczema on The Face', 'Dermatitis on The Face', 'Dullness', 'Loss of Elasticity', 'Psoriasis', 'Hypopigmentation (lighter spots)', 'Hypopigmentation (dark spots, uneven skin tone)', 'Sensitive (redness, irritation) to skincare products', 'Other (specify)'],
  },
  {
    id: 'question6',
    label: 'What is the radiance level of your skin?',
    options: ['High radiance', 'Moderate radiance', 'Low radiance', 'Variable radiance', 'No radiance'],
  },
  {
    id: 'question7',
    label: 'How often do you experience breakouts or skin irritation?',
    options: ['Rarely or Never', 'Occasionally', 'Frequently'],
  },
  {
    id: 'question8',
    label: 'Are there any lifestyle factors or situations that may impact your skin condition?',
    options: ['Stress', 'Too Much Sugar', 'Too Much Fat', 'Pollution (air pollution)', 'Pregnancy', 'Inadequate Sleep', 'Sickness', 'Medication', 'Hormone Therapy', 'Frequent Sun Exposure (more than 2 hours a day)', 'Too Much Coffee (more than 4 cups a day)', 'Alcohol Consumption (more than 10 glasses per week)', 'Low Humidity Levels (for an example indoors)'],
  },
  {
    id: 'question9',
    label: 'Did you undergo non-invasive cosmetic procedures the last 24 months?',
    options: ['Botox Injections', 'Dermal Fillers', 'Chemical Peels', 'Microdermabrasion', 'Laser Hair Removal', 'Micro Needling (home use or professional)', 'Ultherapy (ultrasound therapy for skin tightening)', 'Radio frequency Skin Tightening', 'Intense Pulsed Light (IPL) Therapy for Skin Rejuvenation', 'Platelet-Rich Plasma (PRP) Therapy', 'Microcurrent Facials', 'Led Light Therapy (red, blue, amber, green)', 'Low-Level Laser Therapy (LLLT)', 'Other (specify)'],
  },
  {
    id: 'question10',
    label: 'Did you undergo invasive cosmetic procedures the last 20 years?',
    options: ['Facial Rejuvenation Surgery (facelift, brow lift, eyelid, jawline, neck lift...)', 'Rhinoplasty (Nose Reshaping Surgery)', 'Chin Augmentation (Genioplasty)', 'Cheek Augmentation (Malar or Submalar Augmentation)', 'Lip Augmentation / Lift / Reduction', 'Facial implants', 'Fat Grafting (transfer)', 'Facial Feminization Surgery (FFS)', 'Facial Masculinization Surgery (FMS)'],
  },
  {
    id: 'question11',
    label: 'What does your morning routine look like?',
    options: ['Cleanser', 'Toner', 'Moisturizer', 'Eye Cream', 'Sunscreen', 'Mask', 'Primer (in case of make-up)', 'Make-up', 'Make-up Setting Spray', 'Exfoliation', 'Serum or Treatment Products', 'Other (specify)'],
  },
  {
    id: 'question12',
    label: 'What does your evening routine look like?',
    options: ['Demake-Up', 'Cleanser', 'Toner', 'Moisturizer', 'Exfoliation', 'Mask', 'Eye cream', 'Lip Care', 'Serum or Treatment Products', 'Other (specify)'],
  },
  {
    id: 'question13',
    label: 'What does your mid-day skincare routine look like?',
    options: ['Cleanser', 'Toner', 'Essence or Hydrating Mist', 'Refreshing Mist', 'Eye Cream', 'Lip Cream', 'Moisturizer', 'Sun Protection (SPF Touch-up)', 'Oil Blotting Sheets (for oily skin)', 'Other (specify)'],
  },
  {
    id: 'question14',
    label: 'How consistent are you in your skincare routine?',
    options: ['Morning: Daily', 'Morning: Every Other Day', 'Morning: Few Times a Month', 'Evening: Daily', 'Evening: Every Other Day', 'Evening: Few Times a Month', 'Special Treatment: Daily', 'Special Treatment: Every Other Day', 'Special Treatment: Few Times a Month'],
  },
  {
    id: 'question15',
    label: 'Do you add support to your skincare with supplements?',
    options: ['Vitamins (A, C, D, E)', 'Collagen Peptides', 'Zinc Supplements', 'Probiotics', 'Silica', 'Turmeric/Curcumin', 'Selenium', 'Copper', 'Biotin', 'Omega-3 Fatty Acids', 'Hyaluronic Acid', 'Antioxidants'],
  },
  {
    id: 'question16',
    label: 'How much water do you drink daily?',
    options: ['Less than 1L', 'Between 1l and 1,5l ', ' More than 1,5L'],
  },
  {
    id: 'question17',
    label: 'Do you add holistic methods to your skincare?',
    options: ['Meditation', 'Yoga', 'Regular Exercise', 'Facial Cupping', 'Face Yoga', 'Facial Massage', 'Deep Breathing Techniques', 'Gua Sha (jade, rose quartz)', 'Herbal Remedies (aloe vera, green tea, matcha, chamomile, calendula, lavender, and rosehip oil)', 'Consuming Nutrient-Dense Foods such (fruits, vegetables, whole grains, lean proteins, and healthy fats)', 'Other (specify)'],
  },
  {
    id: 'question18',
    label: 'How much time do you spend daily (morning/evening) on your skincare routine?',
    options: ['Less than 5 min', '5 -10 min', '10 – 15 min', 'More than 15 min'],
  },
  {
    id: 'question19',
    label: 'What is your monthly budget for skincare?',
    options: ['0 – 50 euro', '50 – 100 euro', '> 100 euro'],
  },
  {
    id: 'question20',
    label: 'Did you consult a dermatologist the last 5 years?',
    options: ['Yes', 'No'],
  },
  {
    id: 'question21',
    label: 'How many times do you go to a skincare professional per year?',
    options: ['0 – 3 times', '3 – 6 times', '6 – 12 times', 'Never'],
  },
];

export default questions;
