import axios from 'axios';
import AuthService from '../service/AuthService';

const BASE_PROD_AUTH = 'https://skintwin-e6e80758a8c5.herokuapp.com/auth/';
const BASE_LOCAL_AUTH = 'http://localhost:8080/auth/';

const BASE_AUTH = BASE_PROD_AUTH;

const instance = axios.create({
    baseURL: BASE_AUTH,
});

instance.interceptors.request.use(
    config => {
        const token = AuthService.getToken();
        if (token !== null && token !== undefined) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default instance;
