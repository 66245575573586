import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthService from '../service/AuthService';

const ProtectedRoute = (props:any) => {
    const location = useLocation();
    if (AuthService.hasCompletedQuestionnaire() && location.pathname === '/questionnaire') {
        console.log('ProtectedRoute triggered');
        return <Navigate to='/feed' />;
    }
    return <Outlet />;
};

export default ProtectedRoute;
