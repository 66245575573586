import {Link} from "react-router-dom";

import Header from './common/Header';
import Footer from './common/Footer';
import "./About.css";

const About: React.FC = () => {
    return (
        <div>
        <Header />
            <div className="about-title mt-3 mb-2">Who are we?</div>
            <p className="about-text">
                We are SkinTwin, the innovators of skin management. Skin care isn&#39;t just a routine; it&#39;s a
                transformative journey we embark on with you, reshaping the way you experience your skin for the better.{" "}
                <br />
                <br />
                Our mission is fueled by a passion for empowering individuals to embrace their skin&#39;s unique needs,
                guiding them towards confidence and radiance. From personalized skincare recommendations, shared experiences
                to expert advice, we strive to make skin management effortless and enjoyable, because everyone deserves to
                feel comfortable and empowered in their own skin. Our journey involves constant reinvention and learning.
                <br />
                <br />
                Our story began with a vision to revolutionize skin care for everyone, and it continues to evolve as we expand
                our offerings and embrace new possibilities
                <br />
                <br />
                At Skintwin the pursuit of reimagining skin management is perpetual, driving us to explore, innovate, and
                redefine what it means to glow from within, individually and together.
            </p>
            <h2 className="text-center mt-4 mb-3">A platform dedicated to skincare.</h2>
            <div className="register-container d-flex-row justify-content-space-between mb-5">
                <img className="about-img" alt="Art where human face is made as small puzzle pieces." src="/images/about/adobe-stock-about-page.png"/>
                <div className="about-inner-flex-container">
                    <img className="about-skintwin-logo-no-text" alt="Skintwin logo without text" src="/images/home/skintwin-logo-no-text.png"/>
                </div>
                <h1 className="text-center first-register-text">Empowering connections.</h1>
            </div>
            <Footer />
        </div>
    );
};

export default About;

