import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import Modal from '../common/Modal';
import NewPostForm from '../feed/NewPostForm';
import Sidebar from '../common/Sidebar';
import Advertising from '../common/Advertising';
import Post from './Post';
import { Post as PostType } from '../../interfaces';

import './Feed.css';

const Feed: React.FC = () => {
    const [posts, setPosts] = useState<PostType[]>([]);
    const [search, setSearch] = useState('');
    const [showNewPostForm, setShowNewPostForm] = useState(false);

//     useEffect(() => {
//         axios.get('/posts').then(response => {
//             setPosts(response.data);
//         });
//     }, []);

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
          const response = await axios.get('posts');
          setPosts(response.data);
          console.log('Fetch posts: ',response.data);
        } catch (err) {
          console.error('Error fetching posts:', err);
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const filteredPosts = posts.filter((post) =>
        post.title.toLowerCase().includes(search.toLowerCase()) ||
        post.content.toLowerCase().includes(search.toLowerCase()) /*||
        post.user.name.toLowerCase().includes(search.toLowerCase())*/
    );

    const handleNewPostClick = () => {
        setShowNewPostForm(true);
    };

    const handleCloseModal = () => {
        setShowNewPostForm(false);
    };

    const handleNewPostSuccess = () => {
        setShowNewPostForm(false);
        fetchPosts();
    };

    return (
      <div className="feed-container">
      <div className="feed-sidebar"><Sidebar onNewPostClick={handleNewPostClick} /></div>
      <Modal show={showNewPostForm} onClose={handleCloseModal}>
          <NewPostForm onSubmitSuccess={handleNewPostSuccess} />
      </Modal>
        <div className="feed d-flex-column">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search posts..."
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          <div className="post-container">
            <div className="posts">
              <h3>New posts</h3>
              {filteredPosts.map((post) => (
                <div key={post.id} className="post">
                  <Post key={post.id} post={post} />
                  {/*<small>By {post.user.name}</small>*/}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="feed-ad">
            <Advertising />
        </div>
      </div>
    );
};

export default Feed;