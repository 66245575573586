import React, { useState, useEffect } from 'react';
import { Reply as ReplyType } from '../../interfaces';
import ReplyForm from './ReplyForm';
import axios from '../api/axios';

import './Reply.css';

interface ReplyProps {
    reply: ReplyType;
}

const emojis = ["👍", "❤️", "😂", "😮", "😢", "👏"];
const defaultEmoji = "👍";

const Reply: React.FC<ReplyProps> = ({ reply }) => {
    const [showChildReplies, setShowChildReplies] = useState(false);
    const [childReplies, setChildReplies] = useState<ReplyType[]>(reply.childReplies);
    const [loadingChildReplies, setLoadingChildReplies] = useState(false);
    const [emojiCounts, setEmojiCounts] = useState<Record<string, number>>(reply.likes || {});
    const [userSelectedEmoji, setUserSelectedEmoji] = useState<string | null>(null);
    const [showEmojiMenu, setShowEmojiMenu] = useState(false);

    useEffect(() => {
        fetchReplyReactions();
    }, []);

    const fetchChildReplies = async () => {
        setLoadingChildReplies(true);
        try {
            const response = await axios.get(`replies/child/${reply.id}`);
            console.log('Fetch child replies', response.data);
            setChildReplies(response.data);
        } catch (error) {
            console.error('Error fetching replies:', error);
        } finally {
            setLoadingChildReplies(false);
        }
    };

    const handleChildReplyClick = () => {
        if (!showChildReplies) {
            fetchChildReplies();
        }
        setShowChildReplies(!showChildReplies);
    };

    const handleChildReplySuccess = (newReply: ReplyType) => {
        setChildReplies([...childReplies, newReply]);
    };

    const fetchReplyReactions = async () => {
        try {
            const response = await axios.get(`replies/${reply.id}/like`);
            console.log('Fetch reply reactions: ', response.data);
            setEmojiCounts(response.data.emojiCounts || {});
            setUserSelectedEmoji(response.data.userSelectedEmoji);
        } catch (error) {
            console.error('Error fetching reply reactions:', error);
        }
    };

    const handleLikeClick = () => {
        setShowEmojiMenu(!showEmojiMenu);
    };

    const handleEmojiSelect = async (emoji: string) => {
        try {
            const response = await axios.post(`replies/${reply.id}/like`, { emoji });
            console.log('Update reply reactions: ', response.data);
            setEmojiCounts(response.data.emojiCounts || {});
            setUserSelectedEmoji(response.data.userSelectedEmoji || null);
        } catch (error) {
            console.error('Error liking reply:', error);
        }
        setShowEmojiMenu(false);
    };

    // Generate the display text for the button based on emoji counts
    const emojiButtonText = Object.entries(emojiCounts)
        .filter(([_, count]) => count > 0)
        .map(([emoji, count]) => (
            <span
                key={emoji}
                className={emoji === userSelectedEmoji ? 'highlighted-emoji' : ''}
            >
                {emoji} {count}
            </span>
        ));

    return (
        <div className="reply">
            <img src={reply.user.profilePicture} alt="User" className="reply-user-photo" />
            <div className="reply-content">
                <span className="reply-username">{reply.user.name}</span>
                <p>{reply.content}</p>
                <div className="reply-actions mt-2">
                    <button className="reply-action-button" onClick={handleLikeClick}>
                        {emojiButtonText.length > 0 ? emojiButtonText : defaultEmoji}
                    </button>
                    {showEmojiMenu && (
                        <div className="emoji-menu">
                            {emojis.map(emoji => (
                                <button
                                    key={emoji}
                                    className="emoji-button"
                                    onClick={() => handleEmojiSelect(emoji)}
                                >
                                    {emoji}
                                </button>
                            ))}
                        </div>
                    )}
                    <button className="reply-action-button" onClick={handleChildReplyClick}>
                        {showChildReplies ? "Hide Replies" : "Reply"}
                    </button>
                </div>
                {showChildReplies && (
                    <div className="reply-replies">
                        {loadingChildReplies ? (
                            <p>Loading replies...</p>
                        ) : (
                            childReplies.map(childReply => <Reply key={childReply.id} reply={childReply} />)
                        )}
                        <ReplyForm parentReplyId={reply.id} onReplySuccess={handleChildReplySuccess} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Reply;