// import React, { useEffect, useState } from 'react';
import {Link, Navigate} from 'react-router-dom';

import Header from './common/Header';
import Footer from './common/Footer';
import './Home.css';

const Home: React.FC = () => {

  return (
      <div>
      <Header />
        <div className="d-flex-row justify-content-flex-end mt-3 mb-3 text-right">
            <div className="text-overlay-first">Find your</div>
            <div className="img-container img-face-pieces-container">
                <img className="img-face-pieces" alt="Art where human face is made as small puzzle pieces." src="/images/home/human-puzzle-pieces.png"/>
                <div className="text-overlay-second">skintwin.</div>
            </div>
        </div>
        <h2 className="text-center mb-3">A platform dedicated to skincare.</h2>
        <div className="register-container d-flex-row justify-content-space-between">
            <img className="first-register-img" alt="Woman covering one eye with her hand." src="/images/home/first-register-image.png"/>
            <div className="first-inner-flex-container">
                <img className="skintwin-logo-no-text" alt="Skintwin logo without text" src="/images/home/skintwin-logo-no-text.png"/>
                <Link to="/signup" className="btn register-button-now">Register</Link>
            </div>
            <h1 className="text-center first-register-text">For everyone who wants to transform their skin care journey into radiance and confidence.</h1>
        </div>
        <div className="register-container d-flex-row justify-content-space-between mt-5">
            <div className="second-inner-flex-container">
                <h1 className="text-center second-register-text">Whether you know a lot about skincare or nothing at all, connect with your Skintwin, and achieve glowing skin together.</h1>
                <Link to="/signup" className="btn register-button-now">Register</Link>
            </div>
            <img className="second-register-img" alt="Element" src="/images/home/second-register-image.png"/>
        </div>
        <Footer />
      </div>
  );
};

export default Home;
