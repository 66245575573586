import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import './ResetPassword.css';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values) => {
      try {
        await axios.post('http://localhost:8080/api/auth/reset-password', values);
        navigate('/reset-password-success');
      } catch (error) {
        console.error('Error resetting password:', error);
      }
    },
  });

  return (
    <div className="reset-password-container">
      <h1>Reset Password</h1>
      <form onSubmit={formik.handleSubmit} className="reset-password-form">
        <div className="form-group">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>
        <button type="submit" className="submit-button">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
