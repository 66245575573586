import axios from 'axios';

const BASE_PROD_AUTH = 'https://skintwin-e6e80758a8c5.herokuapp.com/auth/users/';
const BASE_LOCAL_AUTH = 'http://localhost:8080/auth/users/';

const BASE_AUTH = BASE_PROD_AUTH;

class AuthService {
    private tokenKey = 'authToken';
    private userKey = 'user';

    async signIn(email: string, password: string, rememberMe: boolean) {
        try {
            const response = await axios.post(BASE_AUTH + 'signin', { email, password });
            const userData = response.data;

            if (userData.token) {
                if (rememberMe) {
                  localStorage.setItem(this.tokenKey, userData.token); // Assuming the token field is named 'token'
                  localStorage.setItem(this.userKey, JSON.stringify(userData));
                } else {
                  sessionStorage.setItem(this.tokenKey, userData.token);
                  sessionStorage.setItem(this.userKey, JSON.stringify(userData));
                }
            }
            else {
                throw userData;
            }
            return userData;
        } catch (err) {
            throw err;
        }
  }

    async signUp(name: string, email: string, password: string, dateOfBirth: string) {
        try {
            const response = await axios.post(BASE_AUTH + 'signup', {
              name,
              email,
              password,
              dateOfBirth,
            });

            const userData = response.data;
            if (userData.token) {
                sessionStorage.setItem(this.tokenKey, userData.token);
                sessionStorage.setItem(this.userKey, JSON.stringify(userData));
            }
            else {
                throw userData;
            }
            return userData;
        } catch (err) {
            throw err;
        }
    }

//   async submitQuestionnaire(responses: any) {
//     const token = this.getToken();
//     const response = await axios.post(BASE_AUTH + 'questionnaire/submit', responses, {
//       headers: {
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       }
//     });
//
//     const userData = this.getUser();
//     if (userData) {
//       userData.questionnaireAnswered = true;
//       sessionStorage.setItem(this.userKey, JSON.stringify(userData));
//     }
//
//     return response.data;
//   }

  async fetchFeedData() {
    const token = this.getToken();
    const response = await axios.get('http://localhost:8080/api/feed', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  }

    getToken() {
        return localStorage.getItem(this.tokenKey) || sessionStorage.getItem(this.tokenKey);
    }

    getUser() {
        const user = localStorage.getItem(this.userKey) || sessionStorage.getItem(this.userKey);
        return user ? JSON.parse(user) : null;
    }

    signOut() {
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem(this.userKey);
        sessionStorage.removeItem(this.tokenKey);
        sessionStorage.removeItem(this.userKey);
    }

    isAuthenticated() {
        if (!!this.getToken() && this.getToken() !== undefined) {
            return true
        }
        else {
            return false;
        }
    }

    hasCompletedQuestionnaire() {
        const user = this.getUser();
        return user ? user.questionnaireAnswered : false;
    }
}

export default new AuthService();
