import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../service/AuthService';
import axios from '../api/axios';
import Post from '../feed/Post';
import { Post as PostType } from '../../interfaces';

import './Sidebar.css';

interface SidebarProps {
    onNewPostClick: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onNewPostClick }) => {
    const navigate = useNavigate();
    const [posts, setPosts] = useState<PostType[]>([]);
    const [showNewPostForm, setShowNewPostForm] = useState(false);

    const handleLogout = () => {
        const confirmLogout = window.confirm('Are you sure you want to sign out?')
        if (confirmLogout) {
            AuthService.signOut();
            navigate('/signin');
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
        <button className="sidebar-hamburger" onClick={toggleMenu}>
            {/* Hamburger icon (3 lines) */}
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>
        <div className="sidebar-container">

          <div className={`d-flex-column sidebar-links ${isOpen ? 'open' : ''}`}>
            <Link className="sidebar-logo" to="/"><img alt="feed icon" src="/images/sidebar/skintwin-logo-purple-text-bigger-2.png"/></Link>
            <button className="btn new-post-button mb-2" onClick={onNewPostClick}>Make a post</button>
            <nav className="nav-menu mb-1">
            <h2 className="mb-1">Discover</h2>
            <ul>
              <Link to="/feed"><li><img className="nav-icon" alt="home icon" src="/images/sidebar/home.svg"/>Feed</li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="account icon" src="/images/sidebar/account.svg"/>Account</li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="profile icon" src="/images/sidebar/profile.svg"/>Profile</li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="topics icon" src="/images/sidebar/topics.svg"/>Topics</li></Link>
            </ul>
            </nav>
            <nav className="nav-menu mb-1">
            <h2 className="mb-1">For you</h2>
            <ul>
              <Link to="/feed"><li><img className="nav-icon" alt="skincare routine icon" src="/images/sidebar/skincare-routine.svg"/>Skincare Routine</li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="inci decoder icon" src="/images/sidebar/inci-decoder.svg"/>INCI Decoder</li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="shop icon" src="/images/sidebar/shop.svg"/>Shop</li></Link>
            </ul>
            </nav>
            <nav className="nav-menu mb-1">
            <ul>
              <Link to="/feed"><li><img className="nav-icon" alt="find dermatologist icon" src="/images/sidebar/find-dermatologist.svg"/><span>Find a Dermatologist</span></li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="find beautician icon" src="/images/sidebar/find-beautician.svg"/>Find a Beautician</li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="about icon" src="/images/sidebar/about.svg"/>About Skintwin</li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="advertising icon" src="/images/sidebar/advertising.svg"/>Advertising</li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="help icon" src="/images/sidebar/help.svg"/>Help</li></Link>
              <Link to="/feed"><li><img className="nav-icon" alt="blog icon" src="/images/sidebar/blog.svg"/>Blog</li></Link>
            </ul>
            </nav>
            <nav className="nav-menu mb-1">
            <div className="d-flex-row justify-content-space-between"><img className="nav-icon" alt="content policy icon" src="/images/sidebar/content-policy.svg"/><p>Content Policy</p></div>
            <div className="d-flex-row justify-content-space-between"><img className="nav-icon" alt="privacy policy icon" src="/images/sidebar/privacy-policy.svg"/><p>Privacy Policy</p></div>
            <div className="d-flex-row justify-content-space-between"><img className="nav-icon" alt="user agreement icon" src="/images/sidebar/user-agreement.svg"/><p>User Agreement</p></div>
            </nav>
            <div className="profile-section mt-1 mb-2">
              <img src="/images/sidebar/profile-pic.png" alt="profile" className="profile-picture" />
              <button className="btn logout-button" onClick={handleLogout}>Logout</button>
            </div>
          </div>
        </div>
        </div>
    );
};

export default Sidebar;